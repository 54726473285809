export enum UserAuthState {
    STANDBY,
    SIGNED_IN,
    SIGNING_IN,
    LINKING_USER_PROVIDER,
    SELECTING_WEB3_PROVIDER,
    ERROR 
}

export enum LoginType {
    WEB2,
    WEB3
}

export enum WEB3Provider {
    METAMASK = 'Metamask',
    PHANTOM = 'Phantom'
}

export enum LoginProvider {
    METAMASK = 'Metamask',
    PHANTOM = 'Phantom'
}

export interface UserAuthFlow {
    currentState: UserAuthState
    loginType: LoginType
    web3WalletProvider: WEB3Provider
    web3selectedAccount: string
    currentStatusMessage: string
    userId: string
    jwtToken: string
}