import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ACCOUNT_TYPE } from '../../../models/modal';
import { linkTelegramAccount } from '../../../networking/user/telegram/accountLink';
import { useAppSelector } from '../../../state/hooks';
import { updateUserAuthState } from '../../../state/reducers/user/userAuthFlowReducer';
import { UserAuthState } from '../../../models/user/userAuthFlow';
import HandleAlert, { providerOAUTHError } from '../../../utils/handleAlert';

export default function TelegramAuth() {

  const dispatch = useDispatch();
  const networkState = useAppSelector(state => state.network);
  const authState = useAppSelector(state => state.userAuthFlow);

  useEffect(() => {
    // Define the onTelegramAuth function
    //@ts-ignore
    window.onTelegramAuth = (data) => {
      if (data && data.id) {
        console.log(data);
        let username = (data.username !== null && data.username !== undefined && data.username !== "") ? data.username : "anonymous";
        if (authState.currentState === UserAuthState.LINKING_USER_PROVIDER) {
          linkTelegramAccount({id: data.id, username: username, type: ACCOUNT_TYPE.TELEGRAM}, data, networkState, dispatch);
        }
        if (authState.currentState === UserAuthState.SIGNING_IN) {
          // check if properly signed in
          dispatch(updateUserAuthState({state: UserAuthState.SIGNED_IN, currentStatusMessage: ""}));
        }
      } else {
        HandleAlert(providerOAUTHError("Telegram"), dispatch);
      }
    };

    // Create the Telegram widget script
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    script.setAttribute('data-telegram-login', 'LucidAuth_Bot');
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');

    // Append the script to the div with the "telegram-login" ID
    const telegramLoginDiv = document.getElementById('telegram-login');
    if (telegramLoginDiv) {
      telegramLoginDiv.appendChild(script);
    }

    // Clean up function
    return () => {
      // Remove the script when the component unmounts
      if (script.parentElement === telegramLoginDiv) {
        //@ts-ignore
        telegramLoginDiv.removeChild(script);
      }
    };
  }, [dispatch, networkState, authState]); // Empty dependency array ensures the effect runs only once

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={containerStyle}>
      <div
        id="telegram-login"
        style={{
          overflow: 'hidden',
          colorScheme: 'light dark', // Note: Corrected style attribute name
          border: 'none',
          height: '64px',
          width: '250px',
          backgroundColor: '#72d5fd',
          borderRadius: '0.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
    </div>
  );
}