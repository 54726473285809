import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/pro-light-svg-icons';

import UserAuthFlowComponent from "./AuthFlowComponent";

function SignInModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
  
    return (
        <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.SIGN_IN} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex items-center min-h-full justify-center text-center">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="transform transition-all relative w-1/2 overflow-hidden rounded-lg bg-black text-left shadow-xl">
                        <div>
                            <div className="absolute right-0 top-0 pr-4 pt-3">
                                <button className="flex text-2xl text-gray-300 focus:ring-0 focus:outline-none" onClick={() => closeModal()}>
                                    <FontAwesomeIcon icon={faXmark} size="sm" color="white"/>
                                </button>
                            </div>
                            <UserAuthFlowComponent/>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: false}));
    }

}

export default SignInModal;