import 'web3';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { login, logout } from './networking/user';
import { CHAIN_ID, Network } from './models/network';
import { useAppSelector } from './state/hooks';
import { VerificationPage } from './components/verification';
import { prodNetworkHelper, switchNetworkChain } from './state/reducers/network/networkReducer';

import NavigationBar from './components/navigation/navigationBar/navigationBar';
import HomeBody from './components/home/homeBody/homeBody';
import ProfileBody from './components/profile/body/profileBody';
import SignInModal from './components/user/signInModal';
import ScrollToTop from './ScrollWrapper';
import PricingHomePage from './components/pricing/aboutHome';
import AlertView from './components/alert/alert';
import VideoModal from './components/modal/videoModal';
import ArtModal from './components/modal/artModal';
import SearchTagModal from './components/modal/tagSearchModal';
import ModelSelectorModal from './components/modal/modelSelectorModal';
import ArtCreationPreviewModal from './components/modal/artCreationPreview';
import TagAdjustmentModal from './components/modal/tagAdjustmentModal';
import AccountLinkModal from './components/modal/accountLinkModal';
import VerifyingOauthWebsitePlaceholder from './components/profile/oauth/verifyingAuthPlaceholder';
import CustomModelRequestModal from './components/modal/customModelRequestModal';
import PurchaseTierModal from './components/modal/tierPruchaseModal';
import CommunityBody from './components/communities/communitiesBody';
import ModelInfoModal from './components/modal/modelInfoModal';
import TokenHomePage from './components/token/tokenHomeBody';
import ChannelConfirmationModal from './components/modal/channelRemovalModal';
import AboutBody from './components/docs/docsBody';

import { MetaMaskProvider } from "@metamask/sdk-react";

function App() {

  const dispatch = useDispatch();
  const networkState = useAppSelector(state => state.network);

  /*
  useEffect(() => {

    // On every page reload
    window.onload = (event) => {
      // @ts-ignore
      if (window.ethereum) {
        logout(dispatch);
      }
    }

    // When switching accounts log user out
    // @ts-ignore
    if (window.ethereum) {
        // @ts-ignore
        ethereum.on('accountsChanged', (accounts: any) => {
          logout(dispatch);
      });

      // Handle network switches
      // @ts-ignore
      ethereum.on('chainChanged', (chainId: any) => {
          console.log("chain changed" + chainId);
          // @ts-ignore
          if (window.ethereum._state && window.ethereum._state.accounts && window.ethereum._state.accounts.length !== 0 && CHAIN_ID.MATIC === parseInt(chainId)) {
              if (isLoggedOut(networkState)) {
                login(dispatch);
              } else {
                // @ts-ignore
                dispatch(switchNetworkChain(prodNetworkHelper(parseInt(chainId), window.ethereum._state.accounts[0], networkState.jwtToken)));
              }
          } else {
            logout(dispatch);
          }
      });
    }
  }, [networkState, dispatch]);
  */

  return (
    <MetaMaskProvider
      sdkOptions={{
        dappMetadata: {
          name: "LUCIDAI",
          url: window.location.href,
        }
      }}
    >
      <Router>
          <div className="bg-white">
            <NavigationBar/>
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<HomeBody/>} />
                <Route path="/profile" element={<ProfileBody/>} />
                <Route path="/about" element={<AboutBody/>} />
                <Route path="/community" element={<CommunityBody/>} />
                <Route path="/token" element={<TokenHomePage/>} />
                <Route path="/pricing" element={<PricingHomePage/>} />
                <Route path="/verifying" element={<VerifyingOauthWebsitePlaceholder/>} />
                <Route path="/unverified" element={<VerificationPage/>} />
              </Routes>
            </ScrollToTop>
            <SearchTagModal/>
            <SignInModal/>
            <ModelSelectorModal/>
            <CustomModelRequestModal/>
            <VideoModal/>
            <ArtModal/>
            <ArtCreationPreviewModal/>
            <TagAdjustmentModal/>
            <AccountLinkModal/>
            <PurchaseTierModal/>
            <ChannelConfirmationModal/>
            <ModelInfoModal/>
            <AlertView/>
          </div>
      </Router>
    </MetaMaskProvider>
  );
}

function isLoggedOut(network: Network) {
  return network.selectedAccount === "" || network.jwtToken === "";
}

// TS fix if we want to use modelviewer down the line
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': ModelViewerJSX & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> ;
    }
  }
}

interface ModelViewerJSX {
  src: string
  poster?: string
  autoplay: boolean
}

export default App;