import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { login } from '../../../networking/user';
import { useEffect } from 'react';
import { fetchAndUpdateUserArtState } from '../../../networking/art/userNetworking';
import Lottie from "lottie-react";
import ProfileHeader from '../header/profileHeader';
import connectWallet from "../../../lottieJson/connectWallet.json"
import { JobState } from '../../../state/reducers/artCreation/artCreationSubmissionReducer';
import { verifyIP } from '../../../networking/user/authentication/ipVerification';
import { useNavigate } from 'react-router-dom';
import { updateVerificationStatus } from '../../../state/reducers/network/networkReducer';
import GenerationCollectionSection from "../collections/gnerationCollectionSection";
import ProfileManagementSection from "./management/profileManagementSection";
import ProfileModelSection from './models/profileModelBody';
import UserAuthFlowComponent from '../../user/AuthFlowComponent';
import { UserAuthState } from '../../../models/user/userAuthFlow';

function ProfileBody() {

    const networkState = useAppSelector(state => state.network);
    const authState = useAppSelector(state => state.userAuthFlow);
    const profileCollectionState = useAppSelector(state => state.profileCollection);
    const jobState = useAppSelector(state => state.artCreationSubmission);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        // This function will be called only once on component mount
        verifyUser();
      }, []); // Empty dependency array
    
    const verifyUser = async () => {
    // Your method logic here
        if (!networkState.ipVerificationChecked) {
            const result = await verifyIP(dispatch, networkState);
            if (result) {
                navigate("/unverified");
                dispatch(updateVerificationStatus(true));
            }   
        }
    };
    
    useEffect(() => {
        async function listenForCompletedJobs() {
            let intervalId = setInterval(async () => {
                try {
                    console.log("pending jobs found, listening for completion");
                    const pendingJobs = await fetchAndUpdateUserArtState(dispatch, networkState.selectedAccount, networkState.jwtToken);
                    if (jobState.jobLoading === JobState.GENERATING || !pendingJobs) {
                        clearInterval(intervalId);
                    }
                } catch (error) {
                    console.log("An error occured fetching data for user: " + error);
                }
        
            }, 5000)
        }

        async function fetchUserData() {
            if (networkState.selectedAccount !== "" && !profileCollectionState.initialProfileLoad) {
                const pendingJobs = await fetchAndUpdateUserArtState(dispatch, networkState.selectedAccount, networkState.jwtToken);
                if (pendingJobs) {
                    listenForCompletedJobs();
                }
            } 
            if (networkState.selectedAccount !== "" && !profileCollectionState.initialRewardLoad) {
                //updateClaimableTokensState(dispatch, networkState);
            }
        }
        fetchUserData();
    }, [dispatch, networkState, profileCollectionState, jobState])

    // CHANGE THIS FOR TESTING
    if (authState.currentState === UserAuthState.SIGNED_IN || authState.jwtToken === "") {
        return (
            <div className="flex items-center bg-space-gray justify-center min-h-screen min-w-screen pb-64 overflow-y-hidden">
                <div className="w-full">
                    <UserAuthFlowComponent/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <ProfileHeader></ProfileHeader>
                <div className="max-w-8xl flex justify-center bg-black md:pt-2 px-4">
                    <div className="min-h-screen bg-black"></div>
                </div>
            </div>
        )
    }    
}

export default ProfileBody;