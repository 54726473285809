import DiscordAuth from "../profile/oauth/discordOAuth";
import TelegramAuth from "../profile/oauth/telegramOauth";
import { login } from "../../networking/user";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { useAppSelector } from "../../state/hooks";
import { WEB3Provider, UserAuthState } from "../../models/user/userAuthFlow";
import { updateUserAuthState } from "../../state/reducers/user/userAuthFlowReducer";
import { faArrowTurnDownLeft } from "@fortawesome/pro-regular-svg-icons";

import { SDKState, useSDK } from "@metamask/sdk-react";

export const providers: WEB3Provider[] = [WEB3Provider.METAMASK];

function UserAuthFlowComponent()  {

    const dispatch = useDispatch();

    const authState = useAppSelector(state => state.userAuthFlow);
    const sdkState = useSDK();

    if (authState.currentState === UserAuthState.STANDBY) {
        return (    
            <UserAuthFlowStandbyBody/>
        )
    } else if (authState.currentState === UserAuthState.SELECTING_WEB3_PROVIDER) {
        return (
            <Web3ProviderSelection/>
        )
    } else if (authState.currentState === UserAuthState.SIGNING_IN) {
        return (
            <LoadingComponent/>
        )
    } else if (authState.currentState === UserAuthState.ERROR) { 
        return (
            <ErrorComponent/>
        )
    } else {
        return (
            <LoadingComponent/>
        )
    }

    function UserAuthFlowStandbyBody() {
        return (    
            <div className="py-16">
                <div className="flex justify-center items-center w-full py-2 px-4 md:px-4">
                    <TelegramAuth/>
                </div>
                <div onClick={() => updateState(UserAuthState.SIGNING_IN, "Connecting to Discord...")} className="flex justify-center items-center w-full h-full py-2 px-4 md:px-4">
                    <DiscordAuth/>
                </div>
                <div className="flex justify-center items-center w-full h-full py-2 px-4 md:px-4">
                    <Web3ProviderButton/>
                </div>
            </div>
        )
    }

    function Web3ProviderButton() {
        return (
            <button onClick={() => updateState(UserAuthState.SELECTING_WEB3_PROVIDER, "")} className="flex items-center justify-center bg-orange-300 w-[250px] h-[64px] rounded-lg">
                <FontAwesomeIcon icon={faEthereum} color="white" size="lg" className="mr-[6px]"/>
                <h1 className="text-white text-xs">Log in with Web3</h1>
            </button>
        )
    }

    function Web3ProviderSelection() {
        return (
            <>
                <div className="flex justify-center items-center my-4">
                    <h1 className="text-white text-lg font-secondary">SELECT WALLET</h1>
                </div>
                <div className="flex justify-center">
                    <div className="">
                        <div className="bg-black grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 p-4 rounded-t-lg">
                        {providers.map((provider) =>  
                            Web3ProviderCard(provider)
                        )}
                        </div>
                        <div className="flex justify-start items-center bg-black pl-4 pb-2 rounded-b-lg">
                            <button onClick={() => resetState()} className="flex items-center">
                                <FontAwesomeIcon icon={faArrowTurnDownLeft} color='white' size='1x'/>
                                <h1 className="pl-2 pb-2 text-white font-bold text-lg font-third">back</h1>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    } 

    function Web3ProviderCard(provider: WEB3Provider) {
        const providerName = Object.values(WEB3Provider).find((value) => value === provider);
        return (
            <button onClick={() => connectWallet(sdkState)} key={providerName} className="flex w-32 h-32 bg-black rounded-lg border-white border-2 justify-center">
                <h1 className="text-white font-third">
                  
                </h1>
            </button>
        ) 
    }

    function resetState() {
        if (authState.currentState !== UserAuthState.SIGNING_IN) {
            updateState(UserAuthState.STANDBY, "")
        }
    }

    function LoadingComponent() {
        return (
            <div className="flex w-full justify-center items-center h-96">
                <div className="w-full">
                    <div className="flex items-center justify-center w-full">
                        <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                            <div className="h-12 w-12 rounded-full bg-black"></div>
                        </div>
                    </div>
                    <div className="w-full flex items-center text-center justify-center mt-4">
                        <h1 className="w-3/4 text-white text-lg md:text-2xl font-third">
                            {authState.currentStatusMessage}
                        </h1>
                    </div>
                </div>
            </div> 
        )
    }

    function ErrorComponent() {
        return (
            <>
                <h1 className="text-white">Signing in</h1>
            </>
        )
    }

    function SignOutComponent() {
        return (
            <>
            <h1 className="text-white">Signed out</h1>
            </>
        )
    }

    function connectWallet(sdkState: SDKState) {
        login(dispatch, sdkState)
    }

    function updateState(state: UserAuthState, message: string) {
        dispatch(updateUserAuthState({state: state, currentStatusMessage: message}));
    }
}

export default UserAuthFlowComponent;