import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { ACCOUNT_TYPE } from "../../../models/modal";
import { linkDiscordAccount } from "../../../networking/user/telegram/accountLink";
import { useAppSelector } from "../../../state/hooks";
import { updateUserAuthState } from "../../../state/reducers/user/userAuthFlowReducer";
import { UserAuthState } from "../../../models/user/userAuthFlow";
import HandleAlert, { providerOAUTHError } from '../../../utils/handleAlert';

const dev_auth="https://discord.com/api/oauth2/authorize?client_id=1142605573527961701&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fverifying&response_type=code&scope=identify"
const prod_auth="https://discord.com/api/oauth2/authorize?client_id=1142605573527961701&redirect_uri=https%3A%2F%2Flucidai.art%2Fverifying&response_type=code&scope=identify"

function DiscordAuth() {
    const dispatch = useDispatch();
    const networkState = useAppSelector(state => state.network);
    const authState = useAppSelector(state => state.userAuthFlow);

    function authPopup() {
      const popup = window.open(
        prod_auth,
        '_blank',
        `width=500,height=500`
      );
    
      const checkPopupRedirect = setInterval(async () => {
        if (popup !== null && popup.closed) {
          clearInterval(checkPopupRedirect);
          await updateFlowForClosedPopup();
        } else {
          try {
              if (popup && popup.location.href.includes('/verifying')) {
                  // The popup has been redirected back from Discord's authorization page
                  const popupLocation = popup.location.href;
                  // You can extract the access token from the query parameters
                  const urlSearchParams = new URLSearchParams(popupLocation.split('?')[1]);
                  const accessToken = urlSearchParams.get('code');

                  await handleDiscordOAuthFlow(accessToken);
                  popup.close();
                  clearInterval(checkPopupRedirect);
              }
          } catch (error) {
            if (popup) {
              popup.close();
            }
            clearInterval(checkPopupRedirect);
            await updateFlowForClosedPopup();
            HandleAlert(providerOAUTHError("Discord"), dispatch);
          }
        }
      }, 1000);
    }

    async function handleDiscordOAuthFlow(accessToken: string | null) {
      if (accessToken != null) {
        if (authState.currentState === UserAuthState.LINKING_USER_PROVIDER) {
          await linkDiscordAccount({id: 0, username: "", type: ACCOUNT_TYPE.DISCORD}, accessToken, false, networkState, dispatch);
        }
        if (authState.currentState === UserAuthState.SIGNING_IN) {
          // sign in
          // update flow
        }
      } else {
        HandleAlert(providerOAUTHError("Discord"), dispatch);
      }
    }

    async function updateFlowForClosedPopup() {
      console.log(authState.currentState);
      if (authState.currentState !== UserAuthState.SIGNED_IN && authState.currentState !== UserAuthState.SIGNING_IN) {
        dispatch(updateUserAuthState({state: UserAuthState.STANDBY, currentStatusMessage: ""} ));
      }
    }

    return (
        <button onClick={() => authPopup()} className="flex items-center justify-center rounded-lg w-[250px] h-[64px] text-white bg-discord py-1">
            <FontAwesomeIcon icon={faDiscord} color="white" size="lg" className="mr-2"/>
            <h1 className="text-xs text-white">Log in with Discord</h1>
        </button>
    )
}

export default DiscordAuth;