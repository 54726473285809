import { createSlice } from "@reduxjs/toolkit";
import { 
    LoginType,
    UserAuthFlow, 
    UserAuthState, 
    WEB3Provider 
} from "../../../models/user/userAuthFlow";

const initialUserAuthFlowState: UserAuthFlow = {
    currentState: UserAuthState.STANDBY,
    loginType: LoginType.WEB2,
    web3WalletProvider: WEB3Provider.METAMASK,
    web3selectedAccount: "",
    currentStatusMessage: "",
    userId: "",
    jwtToken: "",
}

const userAuthSlice = createSlice({
    name: "userAuthFlow",
    initialState: initialUserAuthFlowState,
    reducers: {
        updateUserAuthState: (state, action) => {
            state.currentState = action.payload.state
            state.currentStatusMessage = action.payload.statusMessage
        },
        handleWeb3Login: (state, action) => {
            state.currentState = action.payload.state
            state.web3selectedAccount = action.payload.web3selectedAccount
            state.currentStatusMessage = action.payload.statusMessage
            state.jwtToken = action.payload.jwtToken
            state.userId = action.payload.userUUID
            state.loginType = LoginType.WEB3
        }
    }
})

export default userAuthSlice.reducer;
export const { updateUserAuthState, handleWeb3Login } = userAuthSlice.actions;